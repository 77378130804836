<template>
	<el-dialog title="司机列表" top="1vh" width="60%" :visible.sync="is_show_in_Page">
		
		<el-button type="success" @click="open_driver_add_view">添加</el-button>

		<!-- 列表 -->
		<div style="margin:20px 0;height:500px">
			<el-table :data="driver_list" :border="true" :stripe="true" size="small" height="100%" v-loading="loading">
				<el-table-column label="添加时间" prop="creat_time_text" width="190"></el-table-column>
				<el-table-column label="司机">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.driver_info.name}} / {{scope.row.driver_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="对司机显示运费" prop="is_show_freight_for_driver_text"></el-table-column>
				<el-table-column label="备注" prop="mark"></el-table-column>

				<!-- 自动接单 -->
				<el-table-column label="自动接单" width="80">
					<template slot-scope="scope">
						<el-switch
							v-model="auto_case_status_list[scope.row.id]"
							active-color="#13ce66"
							inactive-color="#ff4949"
							@change="auto_case_change(scope.row.id)"
						></el-switch>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="110">
					<template slot-scope="scope">
						<el-button @click="open_driver_edit_view(scope.row)" size="mini" type="text">修改</el-button>
						<el-button @click="driver_del(scope.row)" size="mini" type="text" style="color:red">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 司机添加界面 -->
		<el-dialog 
			title="添加司机" top="2vh" width="450px" 
			:visible.sync="driver_add.is_show" 
			append-to-body custom-class="el-dialog-s" 
		>
			<el-form label-position="left" label-width="80px">
				<el-form-item label="司机电话">
					<el-input type="number" @change="driver_ser_open" v-model="driver_add.form.tel" placeholder="不填表示不指定" clearable>
						<el-button @click="driver_ser" type="success" slot="append">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item label="司机信息">
					<el-input v-model="driver_add.form.name" :disabled="true" clearable></el-input>
				</el-form-item>
				<el-form-item label="显示运费">
					<el-select v-model="driver_add.form.is_show_freight_for_driver" style="width:100%" clearable>
						<el-option label="显示" value="1"></el-option>
						<el-option label="不显示" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="driver_add.form.mark" placeholder="非必填" clearable></el-input>
				</el-form-item>
				<div style="text-align:center">
					<el-button type="primary" @click="driver_add_sub">提交</el-button>
					<el-button type="danger" @click="driver_add_clear">撤销</el-button>
				</div>
			</el-form>
		</el-dialog>

		<!-- 司机修改界面 -->
		<el-dialog 
			title="修改司机" top="2vh" width="450px" 
			:visible.sync="driver_edit.is_show" 
			append-to-body custom-class="el-dialog-s" 
		>
			<el-form label-position="left" label-width="80px">
				<el-form-item label="司机信息">
					<el-input v-model="driver_edit.form.info_text" :disabled="true" clearable></el-input>
				</el-form-item>
				<el-form-item label="显示运费">
					<el-select v-model="driver_edit.form.is_show_freight_for_driver" style="width:100%" clearable>
						<el-option label="显示" value="1"></el-option>
						<el-option label="不显示" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="driver_edit.form.mark" placeholder="非必填" clearable></el-input>
				</el-form-item>
				<div style="text-align:center">
					<el-button type="primary" @click="driver_edit_sub">提交</el-button>
					<el-button type="danger" @click="driver_edit_clear">撤销</el-button>
				</div>
			</el-form>
		</el-dialog>

	</el-dialog>
</template>
<script>
	import {mapState} from 'vuex'
	export default {
		props:{
			bl_info:Object,//线路详情
			is_show:Number,//是否显示
		},
		data() {
			return {

				//列表是否显示
				is_show_in_Page:false,

				//司机列表
				driver_list:[],

				//自动接单状态
				auto_case_status_list:{},

				//加载状态
				loading: false,//loading特效

				//添加司机
				driver_add:{
					is_show:false,
					form:{
						tel:'',
						name:'',
						mark:'',
						user_num:'',
						is_show_freight_for_driver:'2',
					}
				},

				//修改司机
				driver_edit:{
					is_show:false,
					form:{
						id:'',
						info_text:'',
						mark:'',
						user_num:'',
						is_show_freight_for_driver:'2',
					}
				},
			}
		},
		watch:{
			bl_num(new_data){
				this.bl_num_in_Page=new_data
			},
			is_show(new_data){
				if(new_data){
					this.is_show_in_Page=true;
					this.init();
				}
			},
		},
		computed:{
			...mapState(['user_info'])
		},
		methods: {

			//初始化
			init(){

				//如果没有线路编号则不读取数据
				if(!this.bl_info){
					return;
				}

				//列表页初始化
				this.driver_list=[];

				//读取数据
				this.load_data()
			},

			//自动接单
			auto_case_change(driver_id){//启动

				//执行删除
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_real_time_bl_driver_edit_by_admin',
						id:driver_id,
						auto_case_status:this.auto_case_status_list[driver_id]?1:2
					},
					callback:(data)=>{

						//反馈数据
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});
					}
				});
			},

			//司机修改
			open_driver_edit_view(item){//打开修改界面
				this.driver_edit.form.info_text=`${item.driver_info.name} / ${item.driver_info.tel}`
				this.driver_edit.form.user_num=item.driver_num
				this.driver_edit.form.is_show_freight_for_driver=item.is_show_freight_for_driver
				this.driver_edit.form.mark=item.mark
				this.driver_edit.form.id=item.id
				this.driver_edit.is_show=true;
			},
			driver_edit_sub(){//提交
				
				//校验
				if(!this.$my.check.is_num(this.driver_edit.form.id)){
					this.$my.other.msg({
						type:'warning',
						str:'绑定id必须是数字'
					});
					return;
				}

				//提交
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_real_time_bl_driver_edit_by_admin',
						id:this.driver_edit.form.id,
						is_show_freight_for_driver:this.driver_edit.form.is_show_freight_for_driver,
						mark:this.driver_edit.form.mark,
					},
					callback:(data)=>{

						//刷新数据
						this.init()
						
						//关闭弹出层
						this.driver_edit.is_show=false;

						//反馈数据
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});
					}
				})
			},
			driver_edit_clear(){//撤销
				this.driver_edit.is_show=false;
			},

			//司机添加
			open_driver_add_view(){//打开添加界面
				this.driver_add.form.tel='';
				this.driver_add.form.name='';
				this.driver_add.form.user_num='';
				this.driver_add.form.is_show_freight_for_driver='2';
				this.driver_add.form.mark=''
				this.driver_add.is_show=true;
			},
			driver_ser_open(){
				this.driver_add.form.need_read=true;
			},
			driver_ser(){//搜索司机

				//是否输入电话号码
				if(!this.$my.check.is_tel(this.driver_add.form.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'电话号码格式有误'
					});
					return;
				}
				
				//是否不需要读取
				if(!this.driver_add.form.need_read){
					return;
				}

				//读取
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_owner_info',
						truck_owner_tel:this.driver_add.form.tel,
					},
					callback:(data)=>{

						if(data.name=='未实名'){
							this.$my.other.msg({
								type:"warning",
								str:'此用户未完成实名认证'
							});
							return;
						}
						
						//比对是否是自己
						if(this.user_info.user_num==data.user_num){
							this.$my.other.msg({
								type:'warning',
								str:'不能指定自己'
							});
							return;
						}

						//写入数据
						this.driver_add.form.name=data.name
						this.driver_add.form.user_num=data.user_num
						this.driver_add.form.need_read=false
					}
				})
			},
			driver_add_sub(){//提交
				
				//校验
				if(!this.driver_add.form.user_num){
					this.$my.other.msg({
						type:'warning',
						str:'没有输入司机信息,提示:输入电话号码后要点击"查找"按钮'
					});
					return;
				}

				//比对是否是自己
				if(this.user_info.user_num==this.driver_add.form.user_num){
					this.$my.other.msg({
						type:'warning',
						str:'不能指定自己'
					});
					return;
				}

				//提交
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_real_time_bl_driver_add_by_admin',
						bl_num:this.bl_info.bl_num,
						shipper_num:this.bl_info.shipper_num,
						driver_num:this.driver_add.form.user_num,
						is_show_freight_for_driver:this.driver_add.form.is_show_freight_for_driver,
						mark:this.driver_add.form.mark,
					},
					callback:(data)=>{

						//刷新数据
						this.init()
						
						//关闭弹出层
						this.driver_add.is_show=false;

						//反馈数据
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});
					}
				})
			},
			driver_add_clear(){//撤销
				this.driver_add.is_show=false;
			},

			//删除司机
			driver_del(item){

				//询问
				this.$my.other.confirm({
					content:'确定删除司机',
					confirm:()=>{
						
						//执行删除
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_real_time_bl_driver_del_by_admin',
								id:item.id,
							},
							callback:(data)=>{

								//刷新数据
								this.init()

								//反馈数据
								this.$my.other.msg({
									type:'success',
									str:'删除成功'
								});
							}
						});
					}
				});
			},

			//读取数据
			load_data(){

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_real_time_bl_driver_list_by_admin',
						bl_num:this.bl_info.bl_num
					},
					callback:(data)=>{

						//加载完毕
						this.loading=false

						//自动接单状态初始化
						let auto_case_status_list={};

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//显示运费
							switch(item.is_show_freight_for_driver){
								case '1':item.is_show_freight_for_driver_text='显示';break;
								case '2':item.is_show_freight_for_driver_text='不显示';break;
							}
							
							//自动接单状态
							auto_case_status_list[item.id]=item.auto_case_status==1
						}
						
						//渲染
						this.driver_list=data.list
						this.auto_case_status_list=auto_case_status_list;
					}
				});
			},
		}
	}
</script>

<style scoped>
	.el-dialog-s{
		z-index: 11;
	}
</style>